<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :footer-left-actions="footerLeftActions"
      :sums="sums"
      :is-page-show="false"
      api-url="/dc-order-list"
      @orderCancel="orderCancel"
      @downloadExcel="downloadExcel"
    ></custom-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  GOODS_ISSUE_STATE,
  CUSTOM_VIEW_DC_ORDER_LIST,
  YES_NO_OPTIONS,
} from "@/libs/const";

export default {
  name: "Order",
  components: {},
  data: () => ({
    customView: CUSTOM_VIEW_DC_ORDER_LIST,
    columns: [],
    footerLeftActions: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    filters: {},
    statusOptions: [...GOODS_ISSUE_STATE],
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  created() {
    const columns = [
      {
        type: "date-range-filter",
        label: this.$t("labels.create_time_2"),
        placeholder: this.$t("labels.create_time_2"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
        defaultValue: [],
        clearable: false,
      },
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_ids",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        allowPaste: true,
        required: true,
      },
      {
        type: "select-warehouse",
        label: this.$t("labels.from_dc_warehouse"),
        placeholder: this.$t("labels.from_dc_warehouse"),
        name: "id_warehouse",
        hasSort: false,
        sortName: "warehouse_code",
        key: "warehouse_code",
        required: true,
      },
      {
        type: "select-warehouse",
        label: this.$t("labels.to_warehouse"),
        placeholder: this.$t("labels.to_warehouse"),
        name: "id_warehouse_destination",
        hasSort: false,
        sortName: "warehouse_destination_code",
        key: "warehouse_destination_code",
        required: true,
      },
      {
        type: "select-distributor",
        label: this.$t("labels.dc_distributor"),
        placeholder: this.$t("labels.dc_distributor"),
        name: "id_distributor",
        hasSort: false,
        sortName: "id_distributor",
        key: "dc_distributor_name",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.warehouse_status"),
        placeholder: this.$t("labels.warehouse_status"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "status",
        required: true,
        options: this.statusOptions,
      },
      {
        type: "input-filter",
        label: this.$t("labels.export"),
        placeholder: this.$t("labels.export"),
        name: "barcodes",
        hasSort: false,
        sortName: "barcodes",
        key: "barcodes",
        customClass: "white-space-pre-line",
        required: true,
      },
      {
        type: "select-employee",
        label: this.$t("labels.employee_create"),
        placeholder: this.$t("labels.employee_create"),
        name: "id_identity",
        hasSort: false,
        sortName: "identity_name",
        key: "identity_name",
        required: true,
      },
      {
        type: "",
        label: this.$t("labels.note"),
        placeholder: this.$t("labels.note"),
        class: "c-red--text",
        name: "note",
        hasSort: false,
        sortName: "note",
        key: "note",
        required: true,
      },
      {
        type: "button",
        label: "",
        headerAction: "",
        headerActionColor: "",
        headerActionLabel: "",
        action: false,
        required: true,
        buttonActions: [
          {
            action: "orderCancel",
            text: this.$t("labels.cancel"),
            color: "error",
            permissions: [],
            allowKey: "can_cancel",
          },
        ],
      },
    ];
    this.columns = [...columns];

    const footerLeftActions = [
      {
        text: "Excel",
        action: "downloadExcel",
        link: "",
        permissions: [],
        color: "info",
        class: "mr-1",
      },
    ];
    this.footerLeftActions = [...footerLeftActions];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },

    async downloadExcel(filters) {
      if (window.exportWaiting) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      try {
        await httpClient.post("/dc-order-list-export", filters);
        window.exportWaiting = true;
        this.$vToastify.warning(this.$t("messages.loading"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async orderCancel(item) {
      if (!confirm(this.$t("messages.are_you_sure_cancel_order"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/dc-order-cancel", {
          id: item.id,
        });
        this.isLoading = false;
        this.refreshCustomTable();
        this.$vToastify.warning(this.$t("messages.order_cancel_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
